@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900&display=swap");

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

::selection {
  @apply bg-primary  text-white;
}

/* ===== ::-webkit-scrollbar ===== */
::-webkit-scrollbar {
  @apply bg-slate-200 dark:bg-slate-500 w-2;
}

::-webkit-scrollbar-thumb {
  @apply w-2 bg-primary rounded-full;
}

@layer components {
  /* section class */
  .section {
    @apply lg:pt-40 pt-36 lg:pb-16 pb-0;
  }
  
  .section-title {
    @apply pb-4 font-dm sm:text-[36px] text-[32px] font-medium text-gray-900 dark:text-white md:text-[38px] lg:text-[40px];
  }

  .section-text {
    @apply pb-8 text-[15px] font-medium text-gray-600 dark:text-white;
  }
}